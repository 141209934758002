import { globalHTTPResponseHandler } from "../redux/FetchResponseManager/actions";
import { SERVER_URLROOT } from "../util/constants";
import { setCookie } from "../util/helper"; 
import { updateJWTToken } from "../redux/Auth/actions"; 

/**
 * Dispatch to create a fetch
 */
export function fetchRequest(name, method, url, params) {
  return {  
    type: "FETCH",
    name: name,
    method: method,
    url: url,
    params: params
  };
};

export const fetchMiddleware = fetchImplementation => store => next => action => {
  if (action.type === "FETCH") {
    const { params, url, name, method } = action;

    const myHeaders = new Headers();
    const dispatch = store.dispatch;

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", store.getState().auth.jwt)

    const init = {
      method: method,
      headers: myHeaders,
    };

    if (method === "POST" || method === "PUT") {
      init.body = JSON.stringify(params)
    }

    dispatch({
        type: name + "_IS_LOADING"
    });

    return (
      fetchImplementation(SERVER_URLROOT + url, init)
        // Update the JWT access token (if it has been refreshed)
        .then((response) => {
          let authHeader = response.headers.entries().find((e, i) => e[0] === "authorization");
          let token = authHeader ? authHeader[1].slice("Bearer ".length) : null;
          // console.log({ "URL:": url, "Token: ": `...${token.slice(-10)}`});

          if (token) {
            const currentToken = store.getState().auth.jwt;
            if (token !== currentToken) {
              dispatch(updateJWTToken(token));
              setCookie("jwt", token, { path: "/", expires: 1 }); // Lives 1 day max (but is updated on each token refresh)
            }
          }
          return response;
        })

        // Handle non-200 responses here
        .then((response) => {
          dispatch(globalHTTPResponseHandler(response, name));
          if (!response.ok) {
            dispatch({
              type: name + "_HAS_ERROR",
              status: response.status,
              message: response.statusText,
            });
            throw Error(response.statusText);
          }
          return response;
        })

        // Dispatch the success action
        .then((response) => response.json())
        .then((data) => {
          dispatch({
            type: name + "_" + method + "_SUCCESS",
            data: data,
          });
          return data;
        })

        // Handle errors
        .catch((error) => {
          console.error(error);
          dispatch({
            type: name + "_HAS_ERROR",
            requestName: name,
            message: error.message,
          });
        })
    );
  }
  return next(action);
};
